import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { DevCloud } from "@datenbanker/devcloud-client-lib";
import { Theme, Authenticator } from "@datenbanker/react-devcloud-components";
import store from "./store";

DevCloud.init({
  apiToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxZWYxOWEzZC03YTc2LTQ2MDctOWFjOC01ZTAxMGQ0NzUyYjUiLCJzY29wZXMiOlsiZWYwZGI3NDgtODg1OS00MmE2LTlkYjktZTZhZDY1NjZjMjYyIl19.UrjRB21S3NdUX9BMkYVeJ3DvZFX97yksVA-VPq9rhSU",
  services: {
    authentication: {
      user: {
        pool: ["12a02c07-8999-41e9-b6fa-e664f8b828cb"] //0231a2b2-e877-4636-86d2-1e20b4d91838
      }
    },
    customer: {
      person: {
        pool: ["6efaa2c4-44c4-4d12-b294-7635dde6a105"]
      },
      opportunity: {
        pool: ["46321b9c-7abd-4c7d-8e1e-3c2c96e20c96"]
      },
      task: {
        pool: ["b7c6ab92-1b8f-433b-8cca-9e361c998263"]
      }
    }
  },
  groups: {
    "06e666f6-9628-4b78-bb94-be97ebc2f7d2": "Basic",
    "7f03628d-e757-48b6-9399-262351f3b2ca": "Person"
  },
  handler: {} // handler assigend in app component because they need redux
});
Theme.init();

ReactDOM.render(
  <Provider store={store}>
    <Authenticator>
      <App />
    </Authenticator>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
